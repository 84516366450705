import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import LatestWorkLayout from "../../templates/latestWork/LatestWork.component.js";
export const _frontmatter = {
  "title": "Ecommerce website",
  "path": "/latest-works/ecommerce",
  "date": "2020-09-19T00:00:00.000Z",
  "description": "This is an ecommerce website built following the Complete React Developer course. The site use Firebase Authentication and FireStore for managing sign-in/sign-up and database. It also use Redux and Redux-Saga for managing shopping operation. Stripe library is used for handling payment part.",
  "img": "ecommerce-laptop.png",
  "imgAlt": "Ecommerce web image",
  "url": "https://ecommerce-dreamofi.vercel.app/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LatestWorkLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>An Ecommerce website</h1>
    <p>{props.pageContext.frontmatter.description}</p>
    <h2>{`Main features`}</h2>
    <ul>
      <li parentName="ul">{`Users can sign-in/sign-up using email and Google account`}</li>
      <li parentName="ul">{`Users can add products to cart and proceed to checkout`}</li>
      <li parentName="ul">{`The session is persisted in sessionStorage`}</li>
    </ul>
    <h2>{`Main incorporated libraries:`}</h2>
    <ul>
      <li parentName="ul">{`React (with Redux and Redux-saga,)`}</li>
      <li parentName="ul">{`Firebase authentication and firestore`}</li>
      <li parentName="ul">{`Styled component`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      